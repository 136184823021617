import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Descriptions, Form, Input, message, Modal, Select, Space, Table, Tabs, Tooltip} from "antd";
import Button from "antd/es/button";
import {
    addCustomHost,
    addSharedHosting,
    loadSharedHostingLocations,
    loadSharedHostingPlans
} from "../../actions/hosting";
import {displayErrors} from "../../libs/utils";
import {MoneyField} from "../shared/MoneyField";
import {CheckOutlined} from "@ant-design/icons";
import AccessItem from "../permissions/AccessItem";

class AddHostingModal extends React.Component {
    state = {
        loading: false,
        accountType: 'SHARED_HOSTING',
        customHostName: '',
        customHostHostname: '',
        customHostIPAddress: '',
        loadingSharedHostingPlans: true,
        sharedHostingPlans: [],
        sharedHostingBillingCycle: 'MONTHLY',
        selectedSharedHostingPlan: '',
        sharedHostingAccountName: '',
        sharedHostingAccountCoupon: '',
        showPackageDetails: false,
        activePackage: '',
        loadingSharedHostingLocations: false,
        sharedHostingLocations: [],
        selectedSharedHostingLocation: ''
    };

    componentDidMount() {
        this.loadSharedHostingPlans();
        this.loadSharedHostingLocations();
    }

    onTabChange(tab) {
        if(this.state.accountType === 'CUSTOM' && tab === 'SHARED_HOSTING') {
            this.loadSharedHostingPlans();
            this.loadSharedHostingLocations();
        }

        this.setState({ accountType: tab });
    }

    loadSharedHostingPlans() {
        this.setState({ loadingSharedHostingPlans: true });

        this.props.loadSharedHostingPlans((res) => {
            this.setState({ loadingSharedHostingPlans: false, sharedHostingPlans: res.data.data });
        }, (err) => {
            this.setState({ loadingSharedHostingPlans: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    loadSharedHostingLocations() {
        this.setState({ loadingSharedHostingLocations: true });

        this.props.loadSharedHostingLocations((res) => {
            this.setState({ loadingSharedHostingLocations: false, sharedHostingLocations: res.data.data,
                selectedSharedHostingLocation: res.data.data[0].guid });
        }, (err) => {
            this.setState({ loadingSharedHostingLocations: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    closeModal() {
        this.setState({
            loading: false,
            accountType: 'CUSTOM',
            customHostName: '',
            customHostHostname: '',
            customHostIPAddress: '',
            loadingSharedHostingPlans: true,
            sharedHostingPlans: [],
            sharedHostingBillingCycle: 'MONTHLY',
            selectedSharedHostingPlan: '',
            sharedHostingAccountName: '',
            sharedHostingAccountCoupon: '',
            showPackageDetails: false,
            activePackage: ''
        });

        this.props.close();
    }

    addCustomHost() {
        let hostname = this.state.customHostHostname.trim().toLowerCase();
        let ip_address = this.state.customHostIPAddress.trim();

        if(hostname === '') {
            return message.error('Please enter your server\'s hostname!');
        }

        if(ip_address === '') {
            return message.error('Please enter your server\'s IP address!');
        }

        let data = {
            hostname: hostname,
            ip_address: ip_address
        };

        this.setState({ loading: true });

        this.props.addCustomHost(data, () => {
            this.setState({ loading: false });
            message.success('Custom host successfully added!');

            this.closeModal();
            this.props.reloadHostingAccounts();

        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    addSharedHosting() {
        let name = this.state.sharedHostingAccountName.trim();
        let plan = this.state.selectedSharedHostingPlan.trim();
        let billing_cycle = this.state.sharedHostingBillingCycle.trim();
        let coupon = this.state.sharedHostingAccountCoupon.trim();
        let location = this.state.selectedSharedHostingLocation.trim();

        if(name.length === 0) {
            return message.error('Please enter your hosting account\'s name!');
        }

        if(plan.length === 0) {
            return message.error('Please select the hosting plan!');
        }

        if(billing_cycle.length === 0) {
            return message.error('Please select the billing cycle!');
        }

        if(location.length === 0) {
            return message.error('Please select a hosting location!');
        }

        let data = {
            name: name,
            plan: plan,
            billing_cycle: billing_cycle,
            coupon: coupon,
            location: location
        };

        this.setState({ loading: true });

        this.props.addSharedHosting(data, (res) => {
            this.setState({ loading: false });
            this.closeModal();

            message.success('Order successfully created!');

            this.props.history.push('/billing/invoices/' + res.data.invoice_id);
        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;

        let footerButtons = null;
        let content = '';

        const sharedHostingPlanColumns = [
            { title: 'Name', dataIndex: 'name' },
            { title: 'Storage', align: 'center', dataIndex: 'storage' },
            { title: 'Bandwidth', align: 'center', dataIndex: 'bandwidth' },
            { title: 'RAM', align: 'center', dataIndex: 'memory' },
            { title: 'CPU', align: 'center', dataIndex: 'cpu' },
            { title: 'Inodes', align: 'center', dataIndex: 'inodes' },
            { title: 'Addon Domains', align: 'center', dataIndex: 'addon_domains' },
            { title: 'Price', align: 'center', render: (item, record) => {
                    let amount = 0;

                    if(this.props.user.user.company.currency === 'EUR') {
                        amount = parseFloat(record.price_eur);
                        return <Tooltip title={<span>The base rate is <MoneyField amount={parseFloat(record.price_usd)} currency='USD' /> USD. The conversion rate is updated daily. The final conversion rate is displayed on an invoice.</span>}><span style={{cursor: 'pointer', borderBottom: '1px dotted'}}>≈ <MoneyField amount={amount} currency={this.props.user.user.company.currency} /></span></Tooltip>;
                    } else if(this.props.user.user.company.currency === 'USD') {
                        amount = parseFloat(record.price_usd);
                        return <MoneyField amount={amount} currency={this.props.user.user.company.currency} />;
                    }
            }},
            { title: '', align: 'right', render: (item, record) => {
                let button = <Button size='small' onClick={() => this.setState({ selectedSharedHostingPlan: record.guid})}>Select</Button>;

                if(record.guid === this.state.selectedSharedHostingPlan) {
                    button = <Button size='small' icon={<CheckOutlined />} type='primary' onClick={() => this.setState({ selectedSharedHostingPlan: ''})}>Selected</Button>;
                }

                return <Space>
                    <Button size='small' onClick={() => this.setState({ showPackageDetails: true, activePackage: record})}>Details</Button>
                    {button}
                </Space>;

            }},
        ];

        if(this.state.accountType === 'CUSTOM') {
            footerButtons = <Space>
                <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                <AccessItem scopes={['can_add_hosting_accounts']}>
                    <Button type='primary' onClick={() => this.addCustomHost()} disabled={this.state.loading}>Add Custom Host</Button>
                </AccessItem>
            </Space>;
        } else if(this.state.accountType === 'SHARED_HOSTING') {
            footerButtons = <Space>
                <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                <Button type='primary' disabled={this.state.selectedSharedHostingPlan === '' || this.state.loading} loading={this.state.loading} onClick={() => this.addSharedHosting()}>Place Shared Hosting Order</Button>
            </Space>;

            content = <Fragment>
                <p>Please select the location and the shared hosting plan that you wish to order:</p>
                <Form layout='vertical'>
                    <Form.Item label='Location'>
                        <Select style={{width: '100%'}} placeholder='Select location'
                                loading={this.state.loadingSharedHostingLocations}
                                value={this.state.selectedSharedHostingLocation}
                                onChange={(value) => this.setState({ selectedSharedHostingLocation: value })}>
                            {this.state.sharedHostingLocations.map((location) => {
                                return <Option key={location.guid} value={location.guid}>{location.city}, {location.country_name}</Option>;
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Table
                            size='small'
                            dataSource={this.state.sharedHostingPlans}
                            rowKey={(item) => item.guid}
                            pagination={false}
                            columns={sharedHostingPlanColumns}
                        />
                    </Form.Item>
                    <Form.Item label='Account name'>
                        <Input value={this.state.sharedHostingAccountName} onChange={(e) => this.setState({ sharedHostingAccountName: e.target.value } )} />
                    </Form.Item>
                    <Form.Item label='Coupon'>
                        <Input value={this.state.sharedHostingAccountCoupon} onChange={(e) => this.setState({ sharedHostingAccountCoupon: e.target.value } )} />
                    </Form.Item>
                    <Form.Item label='Billing cycle'>
                        <Select style={{width: '100%'}} defaultValue={this.state.sharedHostingBillingCycle} onChange={(value) => this.setState({ sharedHostingBillingCycle: value})}>
                            <Option value='MONTHLY'>Monthly (1 month)</Option>
                            <Option value='QUARTERLY'>Quarterly (3 months)</Option>
                            <Option value='SEMIANNUALLY'>Semi-annually (6 months)</Option>
                            <Option value='ANNUALLY'>Annually (12 months)</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Fragment>;
        }

        return(
            <Fragment>
                <Modal
                    centered
                    title={'Add Hosting Account'}
                    width={this.state.accountType === 'CUSTOM' ? '500px' : '900px'}
                    open={this.props.showModal}
                    destroyOnClose={true}
                    onCancel={() => this.closeModal()}
                    className='shared-hosting-plan'
                    footer={footerButtons}>

                    <Tabs defaultActiveKey="SHARED_HOSTING" onChange={(tab) => this.onTabChange(tab)}>
                        <TabPane tab="Shared Hosting" key="SHARED_HOSTING">
                            {content}
                        </TabPane>
                        <TabPane tab="Own Server" key="CUSTOM">
                            <p>A custom host will allow you to send your domain visitors to your own web server. <a target='_blank' rel='noopener noreferrer' href='https://support.priorityprospect.com/knowledgebase/article/how-to-use-my-own-web-server-for-my-network'><strong>Click here</strong></a> for more information on how to use your own server.</p>
                            <Form layout='vertical'>
                                <Form.Item label='Hostname' extra={<small><em>The name of your hosting account</em></small>}>
                                    <Input value={this.state.customHostHostname} onChange={(e) => this.setState({ customHostHostname: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='IP address' extra={<small><em>IP address of your server where the traffic will be sent</em></small>}>
                                    <Input value={this.state.customHostIPAddress} onChange={(e) => this.setState({ customHostIPAddress: e.target.value })} />
                                </Form.Item>
                            </Form>
                        </TabPane>
                    </Tabs>
                </Modal>
                <Modal
                    title='Hosting Package'
                    footer={<Button onClick={() => this.setState({ showPackageDetails: false, activePackage: '' })}>Close</Button>}
                    onCancel={() => this.setState({ showPackageDetails: false, activePackage: '' })}
                    open={this.state.showPackageDetails}>
                    <Descriptions column={1} bordered size='small'>
                        <Descriptions.Item label={<span>Package</span>}>{this.state.activePackage.name}</Descriptions.Item>
                        <Descriptions.Item label={<span>Monthly price</span>}><MoneyField amount={this.props.user.user.company.currency === 'EUR' ? this.state.activePackage.price_eur : this.state.activePackage.price_usd } currency={this.props.user.user.company.currency} /></Descriptions.Item>
                        <Descriptions.Item label={<span>Storage</span>}>{this.state.activePackage.storage}</Descriptions.Item>
                        <Descriptions.Item label={<span>Bandwidth</span>}>{this.state.activePackage.bandwidth}</Descriptions.Item>
                        <Descriptions.Item label={<span>Inodes<sup><small>1</small></sup></span>}>{this.state.activePackage.inodes}</Descriptions.Item>
                        <Descriptions.Item label={<span>RAM<sup><small>2</small></sup></span>}>{this.state.activePackage.memory}</Descriptions.Item>
                        <Descriptions.Item label={<span>CPU<sup><small>3</small></sup></span>}>{this.state.activePackage.cpu}</Descriptions.Item>
                        <Descriptions.Item label={<span>Entry processes</span>}>{this.state.activePackage.entry_processes}</Descriptions.Item>
                        <Descriptions.Item label={<span>Active processes</span>}>{this.state.activePackage.active_processes}</Descriptions.Item>
                        <Descriptions.Item label={<span>Email accounts</span>}>{this.state.activePackage.email_accounts}</Descriptions.Item>
                        <Descriptions.Item label={<span>Databases</span>}>{this.state.activePackage.databases}</Descriptions.Item>
                        <Descriptions.Item label={<span>Subdomains</span>}>{this.state.activePackage.subdomains}</Descriptions.Item>
                        <Descriptions.Item label={<span>Parked domains</span>}>{this.state.activePackage.parked_domains}</Descriptions.Item>
                        <Descriptions.Item label={<span>Addon domains</span>}>{this.state.activePackage.addon_domains}</Descriptions.Item>
                    </Descriptions>
                    <p><br />
                        <small><sup>1</sup> Total number of files and folders.</small><br />
                        <small><sup>2</sup> Total available physical memory.</small><br />
                        <small><sup>3</sup> Physical shared CPU cores.</small></p>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});


export default connect(mapStateToProps, { loadSharedHostingPlans, addCustomHost, addSharedHosting,
    loadSharedHostingLocations })(AddHostingModal);