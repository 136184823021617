import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {loadOrder, loadOrderInvoices, updateOrderTags} from "../../actions/billing";
import {Loading} from "../../libs/loading";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Card, Col, Descriptions, Row, Select, Table, message, Button, Tooltip, Space} from "antd";
import {DateFormat} from "../shared/DateFormat";
import {Link} from "react-router-dom";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";
import {DEFAULT_FLAG_SIZE, DEFAULT_PER_PAGE} from "../../config";
import Flag from 'react-world-flags'
import {MoneyField} from "../shared/MoneyField";
import {DeleteOutlined} from "@ant-design/icons";
import CancelOrderModal from "./CancelOrderModal";
import ModifySharedHostingOrderModal from "./ModifySharedHostingOrderModal";
import {hasPermission} from "../permissions/PermissionUtils";
import AccessGate from "../permissions/AccessGate";
import AccessItem from "../permissions/AccessItem";

class OrderView extends React.Component {
    state = {
        order: null,
        loadingOrder: true,
        tags: [],
        invoicesPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        loadingInvoices: false,
        invoices: [],
        showCancellationModal: false,
        showModifyModal: false,
    };

    componentDidMount() {
        this.props.updatePage('Order #' + this.props.match.params.id);

        this.props.loadOrder(this.props.match.params.id, (res) => {
            this.setState({ loadingOrder: false, order: res.data, tags: res.data.tags });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });

        this.loadOrderInvoices();
    }

    loadOrderInvoices(page = null, per_page = null) {
        if(page == null) {
            page = this.state.invoicesPagination.current;
            per_page = this.state.invoicesPagination.pageSize;
        }

        this.setState({ loadingInvoices: true });

        this.props.loadOrderInvoices(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ invoices: res.data.data, loadingInvoices: false,
                invoicesPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingInvoices: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateTags(values) {
        this.setState({ tags: values });

        this.props.updateOrderTags(this.props.match.params.id, { tags: values }, () => {
            message.success('Order tags successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.loadingOrder) {
            return <div className='text-center'><Loading /></div>;
        }

        const { order } = this.state;

        let orderItems = null;

        if(order.order_type === 'IP_ADDRESS_HOSTING') {
            const columns = [
                { title: 'IP address', dataIndex: 'ip_address', render: (item, record) => hasPermission(this.props.company.permissions, ['can_view_ip_addresses']) ? record.ip_address : <Tooltip placement='top' title='Contact your account manager!'><span><em>Hidden!</em></span></Tooltip>},
                { title: 'Status', dataIndex: 'status', render: (item, record) => <StatusIndicator el='badge' status={record.status} />},
                { title: 'Type', dataIndex: 'ip_address_type', render: (item, record) => normalizeEnum(record.ip_address_type) },
                { title: 'Location', render: (item, record) => <span><Flag height={DEFAULT_FLAG_SIZE} code={record.country_code} style={{marginTop: '-2px'}} /> {record.city}, {record.country}</span>},
                { title: 'Domains allowed', dataIndex: 'domains_allowed', align: 'center'},
            ];

            if(hasPermission(this.props.company.permissions, ['can_view_ip_groups'])) {
                columns.push({ title: 'IP group', dataIndex: 'group', render: (item) => <Link to={'/ip-addresses/' + item.guid}>{item.name}</Link>});
            }

            columns.push({ title: 'Monthly Cost', align: 'center', dataIndex: 'monthly_cost', render: (item) => <MoneyField amount={item.amount} currency={item.currency} />});
            columns.push({ title: 'Date cancelled', dataIndex: 'date_cancelled', render: (item, record) => record.status === 'CANCELLED' ? <DateTimeFormat value={record.date_cancelled} /> : '-' });

            orderItems = <Table dataSource={order.ip_addresses}
                                columns={columns}
                                pagination={false} size='small'
                                rowKey={item => item.guid} />;
        }

        let orderType = '';

        if(order.order_type === 'IP_ADDRESS_HOSTING') {
            orderType = 'IP Addresses';
        } else if(order.order_type === 'SHARED_HOSTING') {
            orderType =  'Shared Hosting';
        } else if(order.order_type === 'VPS_HOSTING') {
            orderType =  'VPS';
        } else if(order.order_type === 'DEDICATED_SERVER_HOSTING') {
            orderType =  'Dedicated server';
        } else if(order.order_type === 'DOMAIN') {
            orderType =  'Domain';
        }

        const { Option } = Select;

        const invoiceColumns = [
            { title: 'ID', dataIndex: 'id', render: (item) => <Link to={'/billing/invoices/' + item}>#{item}</Link>},
            { title: 'Amount', align: 'center', dataIndex: 'initial_amount', render: (item, record) => <MoneyField amount={record.initial_amount} currency={record.currency}/>},
            { title: 'Tax', align: 'center', dataIndex: 'initial_tax', render: (item, record) => <MoneyField amount={record.initial_tax} currency={record.currency}/>},
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', align: 'center', dataIndex: 'date_created', render: (item, record) => <DateFormat value={record.date_created} /> },
            { title: 'Due', align: 'center', dataIndex: 'date_due', render: (item, record) => <DateFormat value={record.date_due} /> },
        ];

        let descriptions = '';

        if(order.order_type === 'IP_ADDRESS_HOSTING') {
            let locationsColumns = [
                { title: 'Location', dataIndex: 'location', render: (item) => <span>{item.city}, {item.country}</span>},
                { title: 'IP address count', align: 'center', dataIndex: 'ip_address_count'},
                { title: 'Additional site count', align: 'center', dataIndex: 'additional_site_count'},
            ];

            descriptions = <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Card title='Information' size='small'>
                        <Descriptions bordered size='small' layout='horizontal' column={1} style={{width:'100%'}}>
                            <Descriptions.Item label='ID' style={{width: '50%'}}>#{order.id}</Descriptions.Item>
                            <Descriptions.Item label='Created'><DateFormat value={order.date_created} /></Descriptions.Item>
                            <Descriptions.Item label='Next invoice'><DateFormat value={order.date_next_invoice} /></Descriptions.Item>
                            <Descriptions.Item label='Billing cycle'>{normalizeEnum(order.billing_cycle)}</Descriptions.Item>
                            <Descriptions.Item label='Status'><StatusIndicator el='badge' status={order.status} /></Descriptions.Item>
                            <Descriptions.Item label='Order type'>{orderType}</Descriptions.Item>
                            <Descriptions.Item label='IP address count'>{order.ip_addresses.length}</Descriptions.Item>
                            {order.group !== null ? <Descriptions.Item label='IP address group'><Link to={'/ip-addresses/' + order.group.guid}>{order.group.name}</Link></Descriptions.Item> : ''}
                            <Descriptions.Item label='Tags'>
                                <Select mode="tags"
                                        defaultValue={this.state.tags}
                                        tokenSeparators={[',']} allowClear
                                        open={false}
                                        disabled={!hasPermission(this.props.company.permissions, ['can_edit_orders'])}
                                        maxTagTextLength={10}
                                        placeholder='Enter tags separated by comma and press "Enter" to save.'
                                        onChange={(values) => this.updateTags(values)}
                                        style={{ width: '100%' }}>
                                    {this.state.tags.map((tag) => <Option key={tag} value={tag}>{tag}</Option>)}
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label='Monthly cost'>
                                {order.monthly_cost.currency === 'EUR' ? <Tooltip title='The base rate is in USD. The conversion rate is updated daily. The final conversion rate is displayed on an invoice.'><span style={{borderBottom: '1px dotted #ccc', cursor: 'pointer'}}>≈ <MoneyField amount={order.monthly_cost.amount} currency={order.monthly_cost.currency} /></span></Tooltip> : <MoneyField amount={order.monthly_cost.amount} currency={order.monthly_cost.currency} />}
                            </Descriptions.Item>
                            {order.coupon !== null ? <Descriptions.Item label='Coupon'>{order.coupon}</Descriptions.Item> : ''}
                            {(order.status === 'ACTIVE' || order.status === 'PENDING') && hasPermission(this.props.company.permissions, ['can_add_cancellation_requests']) ? <Descriptions.Item label='Actions'>
                                <Button size='small' icon={<DeleteOutlined />} onClick={() => this.setState({ showCancellationModal: true })}>Cancel Order</Button>
                            </Descriptions.Item> : ''}
                        </Descriptions>
                    </Card>
                </Col>
                <Col xs={24} md={12}>
                    <Card title='Preferences' size='small'>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            <Descriptions.Item label='IP address type'>{normalizeEnum(order.preferences.ip_address_type)}</Descriptions.Item>
                            <Descriptions.Item label='IP address class filter'>{order.preferences.ip_address_class_filter} class</Descriptions.Item>
                            <Descriptions.Item label='Locations'>
                                <Table dataSource={order.preferences.locations} rowKey={item => item.id} size='small' columns={locationsColumns} pagination={false} />
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>;
        } else if(order.order_type === 'SHARED_HOSTING') {
            descriptions = <Card size='small'>
                <Row gutter={[16, 16]}>
                    <Col md={{span: 12}} sm={{span:24}}>
                        <Descriptions bordered size='small' layout='horizontal' column={1} style={{width:'100%'}}>
                            <Descriptions.Item label='ID' style={{width: '50%'}}>#{order.id}</Descriptions.Item>
                            <Descriptions.Item label='Created'><DateFormat value={order.date_created} /></Descriptions.Item>
                            <Descriptions.Item label='Next invoice'><DateFormat value={order.date_next_invoice} /></Descriptions.Item>
                            <Descriptions.Item label='Billing cycle'>{normalizeEnum(order.billing_cycle)}</Descriptions.Item>
                            <Descriptions.Item label='Status'><StatusIndicator el='badge' status={order.status} /></Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col md={{span: 12}} sm={{span:24}}>
                        <Descriptions bordered size='small' layout='horizontal' column={1} style={{width:'100%'}}>
                            <Descriptions.Item label='Type'>{orderType}</Descriptions.Item>
                            <Descriptions.Item label='Tags'>
                                <Select mode="tags"
                                        defaultValue={this.state.tags}
                                        tokenSeparators={[',']} allowClear
                                        open={false}
                                        disabled={!hasPermission(this.props.company.permissions, ['can_edit_orders'])}
                                        maxTagTextLength={10}
                                        placeholder='Enter tags separated by comma and press "Enter" to save.'
                                        onChange={(values) => this.updateTags(values)}
                                        style={{ width: '100%' }}>
                                    {this.state.tags.map((tag) => <Option key={tag} value={tag}>{tag}</Option>)}
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label='Monthly cost'>
                                {order.monthly_cost.currency === 'EUR' ? <Tooltip title='The base rate is in USD. The conversion rate is updated daily. The final conversion rate is displayed on an invoice.'><span style={{borderBottom: '1px dotted #ccc', cursor: 'pointer'}}>≈ <MoneyField amount={order.monthly_cost.amount} currency={order.monthly_cost.currency} /></span></Tooltip> : <MoneyField amount={order.monthly_cost.amount} currency={order.monthly_cost.currency} />}
                            </Descriptions.Item>
                            {order.coupon !== null ? <Descriptions.Item label='Coupon'>{order.coupon}</Descriptions.Item> : ''}
                            {order.order_type === 'SHARED_HOSTING' && order.hosting_account !== null? <Descriptions.Item label='Hosting account'><Link to={'/hosting/' + order.hosting_account.guid}>{order.hosting_account.name}</Link></Descriptions.Item> : ''}
                            {(order.status === 'ACTIVE' || order.status === 'PENDING') && hasPermission(this.props.company.permissions, ['can_add_cancellation_requests']) ? <Descriptions.Item label='Actions'>
                                <Space>
                                    <Button size='small' icon={<DeleteOutlined />} onClick={() => this.setState({ showCancellationModal: true })}>Cancel Order</Button>
                                    {/*hasPermission(this.props.company.permissions, ['can_edit_orders']) ?
                                        <Tooltip title={order.status !== 'ACTIVE' ? 'The order must be active before it can be modified!' : ''}>
                                            <Button size='small' disabled={order.status !== 'ACTIVE'} icon={<ExportOutlined />} onClick={() => this.setState({ showModifyModal: true })}>Modify Hosting Plan</Button>
                                        </Tooltip> : ''*/}
                                </Space>
                            </Descriptions.Item> : ''}
                        </Descriptions>
                    </Col>
                </Row>
            </Card>;
        }

        return(
            <AccessGate scopes={['can_view_orders']}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        {descriptions}
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{marginTop: '10px'}}>
                        {order.order_type === 'IP_ADDRESS_HOSTING' ? <Card size='small' title='Items'>
                            {orderItems}
                        </Card> : ''}
                        {order.order_type === 'SHARED_HOSTING' ? <Card size='small' title='Hosting Plan'>

                            <Table
                                dataSource={[order.shared_hosting_plan]}
                                rowKey={(item) => item.name}
                                pagination={false} size='small' columns={[
                                { title: 'Plan', dataIndex: 'name', width: 150, fixed: 'left' },
                                { title: 'Storage', dataIndex: 'storage' },
                                { title: 'CPU', dataIndex: 'cpu' },
                                { title: 'RAM', dataIndex: 'memory' },
                                { title: 'Inodes', dataIndex: 'inodes' },
                                { title: 'Bandwidth', dataIndex: 'bandwidth' },
                                { title: <Tooltip title='Entry processes'>Entry Proc.</Tooltip>, dataIndex: 'entry_processes' },
                                { title: <Tooltip title='Active processes'>Active Proc.</Tooltip>, dataIndex: 'active_processes' },
                                { title: <Tooltip title='Email accounts'>Email acc.</Tooltip>, dataIndex: 'email_accounts' },
                                { title: 'Databases', dataIndex: 'databases' },
                                { title: 'Subdomains', dataIndex: 'subdomains' },
                                { title: 'Parked Domains', dataIndex: 'parked_domains' },
                                { title: 'Addon Domains', dataIndex: 'addon_domains' },
                            ]} />
                        </Card> : ''}
                    </Col>
                </Row>
                <br />
                <AccessItem scopes={['can_view_invoices']}>
                    <Row>
                        <Col span={24}>
                            <Card size='small' title='Associated Invoices'>
                                <Table dataSource={this.state.invoices}
                                       columns={invoiceColumns}
                                       size='small'
                                       loading={this.state.loadingInvoices}
                                       pagination={this.state.invoicesPagination}
                                       onChange={(pagination) => this.loadOrderInvoices(pagination.current, pagination.pageSize)}
                                       rowKey={item => item.id} />
                            </Card>
                        </Col>
                    </Row>
                </AccessItem>

                <CancelOrderModal
                    show={this.state.showCancellationModal}
                    close={() => this.setState({ showCancellationModal: false })}
                    orderId={order.id} />

                {order.order_type === 'SHARED_HOSTING' ? <ModifySharedHostingOrderModal
                    show={this.state.showModifyModal}
                    activePackage={order.shared_hosting_plan}
                    close={() => this.setState({ showModifyModal: false })}
                    orderId={order.id} /> : ''}
            </AccessGate>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadOrder, updateOrderTags, loadOrderInvoices })(OrderView);