import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, message, Modal, Select, Space} from "antd";
import {createDomainGroup} from "../../actions/domains";
import {loadHostingAccounts} from "../../actions/hosting";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadIPAddressGroups} from "../../actions/ip_addresses";
import AccessItem from "../permissions/AccessItem";

class AddDomainGroupModal extends React.Component {
    state = {
        hostingAccountsLoaded: false,
        hostingAccounts: [],
        ipGroups: [],
        selectedHostingAccount: '',
        name: '',
        loading: false,
        selectedIPGroups: []
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.hostingAccountsLoaded && this.state.hostingAccounts.length === 0 && !this.state.loading) {
            this.setState({ loading: true });

            this.props.loadHostingAccounts(1, 9999, (res) => {
                this.setState({ hostingAccountsLoaded: true, hostingAccounts: res.data.data });

                this.props.loadIPAddressGroups(1, 9999, null, (res) => {
                    this.setState({ ipGroups: res.data.data, loading: false });
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ hostingAccountsLoaded: true, loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ hostingAccountsLoaded: true, loading: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    addDomainGroup() {
        if(this.state.name.trim() === '') {
            return message.error('Please enter group name!');
        }

        if(this.state.selectedHostingAccount.trim() === '') {
            return message.error('Please select the default hosting account!');
        }

        this.setState({ loading: true });

        let data = {
            name: this.state.name,
            default_hosting_account: this.state.selectedHostingAccount,
            ip_address_groups: this.state.selectedIPGroups
        };

        this.props.createDomainGroup(data, () => {
            this.closeModal();
            this.props.reloadGroups();

            message.success('Domain group successfully added!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false});
                displayErrors(err.response.data);
            }
        });
    }

    closeModal() {
        this.setState({ name: '', selectedHostingAccount: '', hostingAccountsLoaded: false, hostingAccounts: [], loading: false });
        this.props.close();
    }

    render() {
        const { Option } = Select;

        return(
            <Modal
                centered
                title='Add Domain Group'
                open={this.props.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <AccessItem scopes={['can_add_domain_groups']}>
                        <Button type='primary' onClick={() => this.addDomainGroup()} disabled={this.state.loading} loading={this.state.loading}>Add Domain Group</Button>
                    </AccessItem>
                </Space>}
                onCancel={() => this.closeModal()}>

                <Form layout='vertical'>
                    <Form.Item label='Name:'>
                        <Input autoFocus onChange={(e) => this.setState({ name: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Default hosting account:'>
                        <Select disabled={this.state.loading} loading={this.state.loading} onChange={(value) => this.setState({ selectedHostingAccount: value })}>
                            {this.state.hostingAccounts.map((account) => {
                                return <Option disabled={account.status !== 'ACTIVE'} key={account.guid} value={account.guid}>{account.name} <small>({normalizeEnum(account.account_type) + ' - ' + normalizeEnum(account.status)})</small></Option>;
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Allowed IP groups:' extra={<small>Leave empty to allow all IP address groups.</small>}>
                        <Select mode='multiple' allowClear disabled={this.state.loading} loading={this.state.loading} onChange={(value) => this.setState({ selectedIPGroups: value })}>
                            {this.state.ipGroups.map((group) => {
                                return <Option key={group.guid} value={group.guid}>{group.name}</Option>;
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createDomainGroup, loadHostingAccounts, loadIPAddressGroups })(AddDomainGroupModal);