import {
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGOUT_FAILURE,
    LOGOUT_IN_PROGRESS,
    LOGOUT_SUCCESS,
    PAGE_WHITE_LABEL_DATA_UPDATED, START_SETUP_WIZARD, STOP_SETUP_WIZARD,
    USER_LOADED,
    USER_LOADING
} from "./types";
import {httpGet, httpPost} from "../libs/http";
import {displayErrors} from "../libs/utils";
import posthog from "posthog-js";

export const loadUser = () => (dispatch) => {
    dispatch({ type: USER_LOADING });

    httpGet('/account/whoami/', (res) => {
        let data = res.data;

        if(typeof res.data.user.company.white_label !== 'undefined') {
            dispatch({ type: PAGE_WHITE_LABEL_DATA_UPDATED, payload: res.data.user.company['white_label'] });
            delete res.data.user.company['white_label'];
        }

        dispatch({ type: USER_LOADED, payload: data});

        posthog.identify(data.user.company.guid, { name: 'Company: ' + data.user.company.guid });

    }, (err) => {
        if(typeof err.response !== 'undefined') {
            dispatch({ type: AUTH_ERROR });
        }
    });
};

export const login = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/login/', data, (res) => {
        if(typeof res.data.session_id === 'undefined' && typeof res.data.verification === 'undefined') {
            dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        }

        onSuccess(res);
    }, (err) => {
        onFailure(err);
    });
};

export const verifyUser2FA = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/login/2fa/', data, (res) => {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });

        onSuccess(res);
    }, (err) => {
        onFailure(err);
    });
};

export const logout = (onSuccess) => (dispatch) => {
    dispatch({ type: LOGOUT_IN_PROGRESS });

    httpPost('/auth/logout/', {}, () => {
        dispatch({ type: LOGOUT_SUCCESS });
        onSuccess();
    }, (err) => {
        if(typeof err.response !== 'undefined') {
            displayErrors(err.response.data);
            dispatch({ type: LOGOUT_FAILURE });
        }
    });
};

export const startSetupWizard = () => (dispatch) => {
    httpPost('/account/setup/', {'start_wizard': true, 'wizard_step': 1}, () => {
        dispatch({ type: START_SETUP_WIZARD });
    }, (err) => {
        if(typeof err.response !== 'undefined') {
            displayErrors(err.response.data);
        }
    });
};

export const stopSetupWizard = () => (dispatch) => {
    httpPost('/account/setup/', {'start_wizard': false, 'wizard_step': 1}, () => {
        dispatch({ type: STOP_SETUP_WIZARD });
    }, (err) => {
        if(typeof err.response !== 'undefined') {
            displayErrors(err.response.data);
        }
    });
};

export const updateSetupWizardStep = (step) => (dispatch) => {
    httpPost('/account/setup/', {'start_wizard': false, 'wizard_step': step}, () => {
        dispatch({ type: STOP_SETUP_WIZARD });
    }, (err) => {
        if(typeof err.response !== 'undefined') {
            displayErrors(err.response.data);
        }
    });
};



export const requestVerification = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/verify/resend/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const registerAccount = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/register/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const requestPasswordReset = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/recover/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const validatePasswordResetToken = (token, onSuccess, onFailure) => (dispatch) => {
    httpGet('/auth/recover/' + token + '/validate/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const resetPassword = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/recover/reset/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const verifyEmail = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/verify/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const fetchWhiteLabelData = (onSuccess, onFailure) => (dispatch) => {
    httpGet('/system/whitelabel/', (res) => onSuccess(res), (err) => onFailure(err));
};