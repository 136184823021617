import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import {
    Alert,
    Button,
    Input,
    message,
    Result,
    Select,
    Space,
    Spin,
    Steps,
    Switch,
    Table,
    Tooltip,
    Typography
} from "antd";
import {addDomains, loadDomainGroupIPAddresses, loadSoftwarePresetsMinimal} from "../../actions/domains";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {Loading} from "../../libs/loading";
import DomainImportOptionsModal from "./DomainImportOptionsModal";
import {displayErrors, normalizeEnum, shuffleArray} from "../../libs/utils";
import {ExclamationCircleOutlined, UsergroupAddOutlined, UserOutlined} from "@ant-design/icons";
import {loadHostingAccounts} from "../../actions/hosting";

class AddDomainsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domains: [],
            domainTable: [],
            loadingIPAddresses: false,
            ipAddressesLoaded: false,
            ipAddresses: [],
            initialIPAddresses: [],
            hostingAccounts: [],
            defaultHostingAccount: '',
            availableIPPosition: 0,
            step: 0,
            domainsRemoved: false,
            showIPChangeModal: false,
            showSoftwareChangeModal: false,
            activeEditDomain: null,
            submittedSuccessfully: false,
            canGoNext: false,
            addingDomains: false,
            submissionResponse: [],
            showImportOptionsModal: false,
            softwareList: [{ key: 'WORDPRESS', name: 'WordPress' }],
            softwarePresetList: []
        };
    }

    componentDidMount() {
        if(!this.state.loadingIPAddresses && this.state.ipAddresses.length === 0 && !this.state.ipAddressesLoaded) {
            this.loadIPAddresses();
            this.loadHostingAccounts();
            this.loadSoftwarePresets();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    loadSoftwarePresets() {
        this.props.loadSoftwarePresetsMinimal((res) => {
            this.setState({ softwarePresetList: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    loadIPAddresses() {
        this.setState({ loadingIPAddresses: true });

        this.props.loadDomainGroupIPAddresses(this.props.group, (res) => {
            this.setState({ ipAddresses: res.data.data, loadingIPAddresses: false, ipAddressesLoaded: true, initialIPAddresses: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true });
                displayErrors(err.response.data);
            }
        });
    };

    loadHostingAccounts() {
        this.props.loadHostingAccounts(1, 9999, (res) => {
            this.setState({ hostingAccounts: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ hostingAccountsLoaded: true });
                displayErrors(err.response.data);
            }
        });
    }

    resetForm = (callback = null) => {
        this.setState({
            domains: [],
            domainTable: [],
            ipAddresses: [],
            availableIPPosition: 0,
            step: 0,
            showIPChangeModal: false,
            submittedSuccessfully: false,
            submissionResponse: [],
            addingDomains: false,
            activeEditDomain: null,
            domainsRemoved: false,
            ipAddressesLoaded: false,
            softwarePresetList: []
        }, callback);
    };

    nextStep() {
        if(this.state.step === 0) {
            if(!this.buildDomainList()) {
                return;
            }
        }

        const step = this.state.step + 1;
        this.setState({step});
    }

    previousStep() {
        const step = this.state.step - 1;

        if(step === 0) {
            this.loadIPAddresses();
        }

        this.setState({step});

        if(step === 0) {
            this.setState({ domains: [], ipGroups: [], ipAddresses: this.state.initialIPAddresses });
        }
    }

    onDomainsAdd(e) {
        let domains = e.target.value.trim().split('\n').map((domain) => {
            return domain.toLowerCase();
        });

        this.setState({'domains': domains});
    }

    buildDomainList() {
        let ipAddresses = this.state.ipAddresses;
        let domainTable = [];
        let tmpDomainList = [];
        let isCustomHost = false;

        for(let i = 0; i < this.state.hostingAccounts.length; i++) {
            if(this.state.hostingAccounts[i].status !== 'DELETED' && this.state.hostingAccounts[i].guid === this.props.defaultHostingAccount) {
                if(this.state.hostingAccounts[i].account_type === 'CUSTOM_HOST') {
                    isCustomHost = true;
                    break;
                }
            }
        }

        for(let j = 0; j < this.state.domains.length; j++) {
            let pieces = this.state.domains[j].split(',');
            let domain = pieces[0].trim();

            if(domain.substring(0, 5) === 'http:' || domain.substring(0, 6) === 'https:') {
                message.error('Domains cannot start with http(s)://!');
                return false;
            }

            if(domain !== '' && tmpDomainList.indexOf(domain) === -1) {
                let ip_address = null;
                let ssl_certificate = false;
                let software = null;
                let software_protocol = null;
                let redirect_to_https = false;
                let software_preset = null;

                if(pieces.length > 1) {
                    for(let i = 0; i < ipAddresses.length; i++) {
                        if(ipAddresses[i].ip_address === pieces[1].trim() && ipAddresses[i].domain_count < ipAddresses[i].domains_allowed) {
                            ip_address = ipAddresses[i];
                            break;
                        }
                    }
                }

                if(ip_address === null) {
                    ipAddresses = shuffleArray(ipAddresses);

                    for(let i = 0; i < ipAddresses.length; i++) {
                        if(ipAddresses[i].domain_count < ipAddresses[i].domains_allowed) {
                            ip_address = ipAddresses[i];
                            break;
                        }
                    }
                }

                if(ip_address === null) {
                    this.setState({ step: 0 }, () => message.error('Unable to assign an IP address because there are no IP addresses left!'));
                }

                ipAddresses = ipAddresses.map((ip) => {
                    if(ip.guid === ip_address.guid) {
                        return {...ip, domain_count: ip.domain_count + 1 };
                    }

                    return ip;
                });

                if(pieces.length >= 3) {
                    let value = pieces[2].trim().toLowerCase();
                    if(value === 'true' || value === '1') {
                        ssl_certificate = true;
                    }
                }

                if(pieces.length >= 4) {
                    let value = pieces[3].trim().toLowerCase();
                    if((value === 'true' || value === '1') && ssl_certificate) {
                        redirect_to_https = true;
                    }
                }

                if(!isCustomHost) {
                    if(pieces.length >= 5) {
                        let value = pieces[4].trim().toUpperCase();

                        for(let i = 0; i < this.state.softwareList.length; i++) {
                            if(this.state.softwareList[i].key === value) {
                                software = this.state.softwareList[i].key;
                                break;
                            }
                        }
                    }

                    if(pieces.length >= 6) {
                        let value = pieces[5].trim().toLowerCase();

                        if((value === 'http://' || value === 'https://' || value === 'http://www' || value === 'https://www') && software !== null) {
                            software_protocol = value;
                        }
                    }

                    if(pieces.length >= 7) {
                        software_preset = pieces[6].trim();
                    }
                }

                tmpDomainList.push(domain);

                domainTable.push({
                    id: j,
                    domain: domain,
                    ip_address: ip_address,
                    ssl_certificate: ssl_certificate,
                    redirect_to_https: redirect_to_https,
                    software: software,
                    software_protocol: software_protocol,
                    software_preset: software_preset,
                    hosting_account: this.props.defaultHostingAccount
                });
            }
        }

        this.setState({ domainTable: domainTable, ipAddresses: ipAddresses });
        return true;
    }

    updateDomainTable(id, record, find, replace) {
        let newDomainData = this.state.domainTable.map((item, index) => {
            if(id === index) {
                let isCustomHost = item.hosting_account.account_type === 'CUSTOM_HOST';

                if(find === 'domain') {
                    if(replace.trim().length === 0) {
                        message.error('Please enter domain name. To delete the domain use the delete button on the right!');
                        return item;
                    }
                    else
                    {
                        return {...item, domain: replace.trim().toLowerCase()};
                    }
                }
                else if(find === 'ip_address') {
                    let ipAddress = null;

                    for(let i = 0; i < this.state.ipAddresses.length; i++) {
                        if(this.state.ipAddresses[i].guid === replace) {
                            ipAddress = this.state.ipAddresses[i];
                            break;
                        }
                    }

                    return {...item, ip_address: ipAddress};
                }
                else if(find === 'software' && !isCustomHost) {
                    if(replace === null) {
                        return {...item, software: replace, software_protocol: null};
                    }

                    let software_protocol = record.software_protocol;

                    if(record.ssl_certificate) {
                        software_protocol = 'https://';
                    }

                    return {...item, software: replace, software_protocol};
                }
                else if(find === 'software_protocol' && !isCustomHost) {
                    let ssl_certificate = record.ssl_certificate;

                    if(replace.substring(0, 5) === 'https') {
                        ssl_certificate = true;
                    }
                    return {...item, software_protocol: replace, ssl_certificate};
                }
                else if(find === 'software_preset' && !isCustomHost) {
                    return {...item, software_preset: replace};
                }
                else if(find === 'ssl_certificate') {
                    if(!replace) {
                        return {...item, ssl_certificate: replace, redirect_to_https: false};
                    }

                    return {...item, ssl_certificate: replace};
                }
                else if(find === 'redirect_to_https') {
                    if(item.ssl_certificate) {
                        return {...item, redirect_to_https: replace};
                    }

                    return {...item, redirect_to_https: false};
                }
                else if(find === 'hosting_account') {
                    let hostingAccount = null;

                    for(let i = 0; i < this.state.hostingAccounts.length; i++) {
                        if(this.state.hostingAccounts[i].guid === replace) {
                            hostingAccount = this.state.hostingAccounts[i];
                            break;
                        }
                    }

                    return {...item, hosting_account: hostingAccount};
                }
            }

            return item;
        });

        let removeRecordIPAddress = false;

        let newIPAddressData = this.state.ipAddresses.map((ip_address) => {
            let domainCount = 0;

            for(let i = 0; i < newDomainData.length; i++) {
                if(newDomainData[i].domain === record.domain && newDomainData[i].ip_address === ip_address.guid && record.ip_address !== ip_address.guid) {
                    removeRecordIPAddress = true;

                    domainCount++;
                    break;
                }
            }

            return {...ip_address, domain_count: ip_address.domain_count + domainCount };
        });

        if(removeRecordIPAddress) {
            newIPAddressData = newIPAddressData.map((ip_address) => {
                if(ip_address.guid === record.ip_address) {
                    return {...ip_address, domain_count: (ip_address.domain_count - 1) };
                }

                return ip_address;
            });
        }

        this.setState({ domainTable: newDomainData, ipAddresses: newIPAddressData });
    }

    deleteDomain(record) {
        let newIPAddressData = this.state.ipAddresses.map((ip_address) => {
            if(ip_address.guid === record.ip_address) {
                let domainCount = ip_address.domain_count - 1;
                return {...ip_address, domain_count: domainCount };
            }

            return ip_address;
        });

        let newDomainData = this.state.domainTable.filter(domain => domain.domain !== record.domain);

        let step = this.state.step;

        if(newDomainData.length === 0) {
            step = 0;
        }

        this.setState({ ipAddresses: newIPAddressData, domainTable: newDomainData, step: step });
    }

    updateSSLCertificate(domain, value) {
        let domainTable = this.state.domainTable.map((record) => {
            if(domain === record.domain) {
                return { ...record, ssl_certificate: value }
            }
            return record;
        });

        this.setState({'domainTable': domainTable});
    }

    saveDomains = () => {
        let data = this.state.domainTable.map(domain => {
            return {
                domain: domain.domain,
                ip_address: domain.ip_address.guid,
                group: this.props.group,
                install_lets_encrypt: domain.ssl_certificate,
                redirect_to_https: domain.redirect_to_https,
                hosting_account: domain.hosting_account.guid,
                install_software: domain.software,
                software_protocol: domain.software_protocol,
                software_preset: domain.software_preset
            }
        });

        this.setState({ addingDomains: true });

        this.props.addDomains(data, () => {
            this.setState({ addingDomains: false, submittedSuccessfully: true, submissionResponse: [], step: 3 });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ addingDomains: false, submittedSuccessfully: false, submissionResponse: err.response.data.errors, step: 3 });
            }
        });
    };

    closeAddSwitchToDomainTab() {
        this.resetForm(() => {
            this.props.closeAddSwitchToDomainTab();
        });
    }

    getSoftwareName(key) {
        for(let i = 0; i < this.state.softwareList.length; i++) {
            if(this.state.softwareList[i].key === key) {
                return this.state.softwareList[i].name;
            }
        }
    }

    render() {
        if(!this.state.ipAddressesLoaded) {
            return <div className='text-center'><Loading /></div>;
        }

        if(this.state.ipAddressesLoaded && this.state.ipAddresses.length === 0) {
            return <Alert type='warning' showIcon message='Please add IP addresses to your account or IP address group to add domains!' />;
        }

        const { Step } = Steps;
        const { Link } = Typography;

        let completedStepIcon = null;

        if(this.state.addingDomains) {
            completedStepIcon = <Spin indicator={<LoadingOutlined />} />;
        }

        let content = '';
        let stepsContent = <Steps current={this.state.step} style={{marginBottom: '25px'}}>
            <Step key='insert' title='Domains' />
            <Step key='actions' title='Actions' />
            <Step key='review' title='Review' icon={completedStepIcon} />
            <Step key='completed' title='Completed' />
        </Steps>;

        let footerButtons = <Space className='add-modal-footer-buttons'>
            {this.state.step > 0 && (<Button disabled={this.state.addingDomains} type='default' onClick={() => this.previousStep()}>Previous</Button>)}
            {this.state.step < 2 && (<Button disabled={this.state.addingDomains || this.state.domains.length === 0} type='primary' onClick={() => this.nextStep()}>Next</Button>)}
            {this.state.step === 2 && (<Button disabled={this.state.addingDomains} loading={this.state.addingDomains} type='primary' onClick={() => this.saveDomains()}>Add Domains</Button>)}
        </Space>;

        if(this.state.step === 0) {
            const { TextArea } = Input;

            content = <Fragment>
                <TextArea onChange={(e) => this.onDomainsAdd(e)} autoSize={{ minRows: 5, maxRows: 15 }} placeholder='One domain per row' />
                <p>You can also import domains from file or paste them to the textbox above. <Link onClick={() => this.setState({ showImportOptionsModal: true })}><b>Click here</b></Link> to see the format.</p>
            </Fragment>;
        } else if(this.state.step === 1)
        {
            const { Option, OptGroup } = Select;

            let ipAddresses = {};

            for(let i = 0; i < this.state.ipAddresses.length; i++) {
                let ipAddress = this.state.ipAddresses[i];

                if(typeof ipAddresses[ipAddress.country] === 'undefined') {
                    ipAddresses[ipAddress.country] = [];
                }

                ipAddresses[ipAddress.country].push(ipAddress);
            }

            let options = Object.keys(ipAddresses).map((country) => {
                let ipOptions = ipAddresses[country].map((ip_address) => {
                    return <Option disabled={ip_address.domain_count >= ip_address.domains_allowed} key={ip_address.guid} value={ip_address.guid}>
                        {ip_address.ip_address_type === 'DEDICATED' ? <Tooltip title='Dedicated'><UserOutlined className='ip-picker-icon' /></Tooltip> : <Tooltip title='Shared'><UsergroupAddOutlined className='ip-picker-icon' /></Tooltip> }
                        {ip_address.ip_address} <small>({ip_address.domain_count + '/' + ip_address.domains_allowed}) {ip_address.city}</small>
                    </Option>;
                });

                return <OptGroup key={country} label={country}>
                    {ipOptions.map((option) => {
                        return option;
                    })}
                </OptGroup>;
            });

            const columns = [
                { title: 'Domain', dataIndex: 'domain', width: '250px', render: (text, record, index) => {
                    return <Input value={record.domain} style={{width: '250px'}} onChange={(e) => this.updateDomainTable(index, record, 'domain', e.target.value)} />;
                }},
                {
                    title: 'IP Address',
                    dataIndex: 'ip_address',
                    render: (text, record, index) => {
                        return <Select style={{width: '250px'}} value={record.ip_address.guid}
                                       onChange={(value) => this.updateDomainTable(index, record, 'ip_address', value)}
                                       showSearch
                                       filterOption={(input, option) => {
                                           if(typeof option !== 'undefined' && Array.isArray(option.children)) {
                                               return option.children[1].indexOf(input.trim()) >= 0;
                                           }
                                           return false;
                                       }}>
                            {options}
                        </Select>;
                    }
                },
                { title: 'Hosting', render: (text, record, index) => {
                        return <Select style={{width: '350px'}} value={record.hosting_account.guid} onChange={(value) => this.updateDomainTable(index, record, 'hosting_account', value)}>
                            {this.state.hostingAccounts.map((account) => {
                                return <Option disabled={account.status !== 'ACTIVE'} key={account.guid} value={account.guid}>
                                    {account.name} <small>({normalizeEnum(account.account_type) + ' - ' + normalizeEnum(account.status)})</small>
                                </Option>;
                            })};
                        </Select>;
                    }
                    },
                { title: 'SSL', dataIndex: 'ssl_certificate', align: 'center',
                    render: (text, record, index) => <Switch size="small" checked={record.ssl_certificate} onChange={(e) => this.updateDomainTable(index, record, 'ssl_certificate', e)} />},
                { title: 'Redirect to HTTPS', dataIndex: 'redirect_to_https', align: 'center',
                    render: (text, record, index) => {
                        return <Switch size="small" disabled={!record.ssl_certificate} checked={record.redirect_to_https} onChange={(e) => this.updateDomainTable(index, record, 'redirect_to_https', e)} />
                    }},
                { title: 'Software', dataIndex: 'software',
                    render: (text, record, index) => {
                        let isCustomHost = record.hosting_account.account_type === 'CUSTOM_HOST';

                        return <Space>
                            <Select disabled={isCustomHost} style={{width: '150px'}} value={record.software} onChange={(value) => this.updateDomainTable(index, record, 'software', value)}>
                                <Option value={null} selected={record.software === null} key='no-software'>None</Option>
                                {this.state.softwareList.map((software) => {
                                    return <Option selected={software.key === record.software} key={software.key} value={software.key}>{software.name}</Option>;
                                })};
                            </Select>
                            {!isCustomHost ? '' : <Tooltip title='Software cannot be automatically installed to custom hosts!'>
                                <ExclamationCircleOutlined style={{color:'red'}} />
                            </Tooltip>}
                        </Space>;
                    }
                },
                { title: 'Protocol', dataIndex: 'protocol', render: (text, record, index) => {
                        let isCustomHost = record.hosting_account.account_type === 'CUSTOM_HOST';
                        let isDisabled = false;

                        if(isCustomHost) {
                            isDisabled = true;
                        } else if(record.software === null) {
                            isDisabled = true;
                        }

                   return <Select style={{width: '130px'}} value={record.software_protocol} disabled={isDisabled} onChange={(value) => this.updateDomainTable(index, record, 'software_protocol', value)}>
                       <Option value='http://'>http://</Option>
                       <Option value='http://www'>http://www</Option>
                       <Option value='https://'>https://</Option>
                       <Option value='https://www'>https://www</Option>
                   </Select>;
                }},
                { title: 'Software Preset', dataIndex: 'software_preset',
                    render: (text, record, index) => {
                        let isCustomHost = record.hosting_account.account_type === 'CUSTOM_HOST';
                        let isDisabled = false;

                        if(isCustomHost) {
                            isDisabled = true;
                        } else if(record.software === null) {
                            isDisabled = true;
                        }

                        return <Space>
                            <Select disabled={isDisabled} style={{width: '150px'}} value={record.software_preset} onChange={(value) => this.updateDomainTable(index, record, 'software_preset', value)}>
                                <Option value={null} selected={record.software === null} key='no-software-presets'>None</Option>
                                {this.state.softwarePresetList.map((preset) => {
                                    let isSelected = false;
                                    if(record.software !== null) {
                                        let selectedSoftware = this.state.softwareList.filter(software => software.key === record.software)[0];

                                        if(selectedSoftware['name'] === preset.software_name) {
                                            isSelected = true;
                                        }
                                    }
                                    return <Option selected={isSelected} key={preset.guid} value={preset.guid}>{preset.name}</Option>;
                                })};
                            </Select>
                            {!isCustomHost ? '' : <Tooltip title='Software cannot be automatically installed to custom hosts!'>
                                <ExclamationCircleOutlined style={{color:'red'}} />
                            </Tooltip>}
                        </Space>;
                    }
                },
                { align: 'right', render: (text, record) => (<Button size='small' danger onClick={() => this.deleteDomain(record)} icon={<DeleteOutlined />} />)}
            ];


            if(this.state.loadingIPAddresses) {
                content = <div style={{textAlign: 'center'}}><Loading /></div>;
            } else {
                content = <Fragment>
                    {this.state.domainsRemoved ? <Fragment><Alert message="Some domains had to be removed due to not having enough free IP addresses!" closable type="error" showIcon /><br /></Fragment> : ''}
                    <div style={{overflowX: 'auto'}}>
                        <Table
                            size='small'
                            pagination={false}
                            rowKey={record => record.id}
                            dataSource={this.state.domainTable}
                            columns={columns} />
                    </div>

                </Fragment>;
            }


        }
        else if(this.state.step === 2)
        {
            const columns = [
                {
                    title: 'Domain',
                    dataIndex: 'domain'
                },
                {
                    title: 'IP Address',
                    dataIndex: 'ip_address',
                    render: (text, record) => (record.ip_address.ip_address)
                },
                {
                    title: 'Hosting',
                    dataIndex: 'hosting_account',
                    render: (text, record) => (record.hosting_account.name)
                },
                {
                    title: 'SSL Certificate',
                    dataIndex: 'ssl_certificate',
                    align: 'center',
                    render: (text, record) => (record.ssl_certificate ? 'Yes' : 'No')
                },
                { title: 'Redirect to HTTPS', dataIndex: 'redirect_to_https', align: 'center', render: (item) => item ? 'Yes' : 'No' },
                {
                    title: 'Software',
                    dataIndex: 'software',
                    align: 'center',
                    render: (text, record) => (record.software === null || record.software === '' ? 'No software' : this.getSoftwareName(record.software))
                },
                { title: 'Protocol', dataIndex: 'software_protocol', align: 'center', render: (item) => item !== null ? item : '-' },
                { title: 'Software Preset', dataIndex: 'software_preset', align: 'center', render: (item) => item !== null ? this.state.softwarePresetList.filter(preset => preset.guid === item)[0].name : '-' },
            ];

            content = <Fragment>
                <Alert message="Please review your submission below." type="info" showIcon />
                <br />
                <Table
                    size='small'
                    rowKey={record => record.domain}
                    dataSource={this.state.domainTable}
                    pagination={false}
                    columns={columns} />
            </Fragment>;
        }
        else if(this.state.step === 3)
        {
            stepsContent = '';
            footerButtons = '';

            if(this.state.submittedSuccessfully)
            {
                content = <Result
                    status="success"
                    title="Domains submitted successfully!"
                    subTitle="Your domains have been submitted successfully to be added to your domain group."
                    extra={[
                        <Button type="primary" key="continue" onClick={() => this.closeAddSwitchToDomainTab()}>Continue</Button>
                    ]}
                />;
            }
            else
            {
                let errors = this.state.submissionResponse.map((error, id) => {
                    return <p key={id}><CloseCircleOutlined style={{color:'red'}} /> {error}</p>
                });

                content = <Result
                    status="error"
                    title="Something went wrong!"
                    subTitle="There were issues with your submissions. Please review them below."
                    extra={[
                        <Button type="primary" key="continue" onClick={() => this.setState({'step': 1})}>Go Back</Button>
                    ]}>
                    {errors}
                </Result>;
            }
        }

        return (
            <Fragment>
                {stepsContent}
                {content}
                <div style={{marginTop: '10px'}}>{footerButtons}</div>

                <DomainImportOptionsModal
                    showModal={this.state.showImportOptionsModal}
                    close={() => this.setState({ showImportOptionsModal: false })} />
            </Fragment>
        )
    }
}

export default connect(null, { addDomains, loadDomainGroupIPAddresses,
    loadHostingAccounts, loadSoftwarePresetsMinimal })(AddDomainsForm);