export const setupWizardSteps = [
    {
        content: <div>
            <h1>Setup Wizard</h1>
            <p>Welcome to Priority Prospect's setup wizard!</p>
        </div>
    },
    {
        selector: '[data-menu-id$=\\/affiliate]',
        content: 'Click here to set up your Affiliate account',
        action: (node) => {
            console.log('Node: ', node)
        }
    }
]