import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, message, Popconfirm, Row, Table, Typography} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors} from "../../libs/utils";
import {createNewPaypalAccount, disablePaymentMethod, loadPaymentMethods} from "../../actions/billing";
import {DeleteOutlined} from "@ant-design/icons";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import AddCardModal from "./AddCardModal";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Loading} from "../../libs/loading";
import Modal from "antd/es/modal/Modal";
import {StatusIndicator} from "../shared/Status";
import AccessGate from "../permissions/AccessGate";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";

class PaymentMethodsTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        paymentMethods: [],
        loadingPaymentMethods: true,
        filter: ['ACTIVE', 'DISABLED'],
        showAddCardModal: false
    }

    componentDidMount() {
        this.props.updatePage('Payment Methods');
        this.loadPaymentMethods();
    }

    loadPaymentMethods(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingPaymentMethods: true });

        this.props.loadPaymentMethods(page, per_page, this.state.filter, (res) => {
            this.setState({ paymentMethods: res.data.data, loadingPaymentMethods: false, pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingPaymentMethods: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(pagination, filters) {
        let filter = [];

        if(filters.status !== null) {
            filter = filters.status;
        }

        this.setState({ filter: filter }, () => {
            this.loadPaymentMethods(pagination.current, pagination.pageSize);
        });
    }

    createPaypalAccount() {
        this.setState({ showAddPaypalModal: true });

        this.props.createNewPaypalAccount( (res) => {
            if(typeof res.data.redirect_url !== 'undefined') {
                window.location.href = res.data.redirect_url;
            }
            else
            {
                message.error('Unable to get the redirect URL, please contact our support!');
            }
        }, (err) => {
            this.setState({ showAddPaypalModal: false });

            if(typeof err.response !== 'undefined') {
                this.setState({ loadingPaymentMethods: false });
                displayErrors(err.response.data);
            }
        });
    }

    disablePaymentMethod(guid) {
        this.props.disablePaymentMethod(guid, () => {
            message.success('Payment method successfully disabled!');
            this.loadPaymentMethods();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingPaymentMethods: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Text } = Typography;
        const columns = [
            { title: 'Name', dataIndex: 'name'},
            { title: 'Details', dataIndex: 'details', render: (item) => <Text code>{item}</Text>},
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator el='badge' status={item} />,
                filters: [
                    { text: 'Active', value: 'ACTIVE' },
                    { text: 'Disabled', value: 'DISABLED' },
                ],
                defaultFilteredValue: ['ACTIVE', 'DISABLED']
            },
            { title: 'Created', dataIndex: 'date_created', render: (item, record) => <DateTimeFormat value={record.date_created} /> },
        ];

        if(hasPermission(this.props.company.permissions, ['can_edit_payment_methods'])) {
            columns.push({ title: '', align: 'right', render: (item, record) => {
                    if(record.status === 'ACTIVE') {
                        return <Popconfirm
                            title="Are you sure you want to disable this payment method?"
                            onConfirm={() => this.disablePaymentMethod(record.guid)}
                            okText='Disable'
                            okButtonProps={{danger: true}}
                            placement="topRight"
                            cancelText='Cancel'>
                            <Button danger size='small' icon={<DeleteOutlined />}>Disable</Button>
                        </Popconfirm>;
                    }
                }});
        }

        return(
            <AccessGate scopes={['can_view_payment_methods']}>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <AccessItem scopes={['can_add_payment_methods']}>
                            <Button type='primary' icon={<PlusOutlined />} onClick={() => this.setState({ showAddCardModal: true })}>Add Card</Button>
                        </AccessItem>
                    </Col>
                    <Col><Button type='text' onClick={() => this.loadPaymentMethods()} icon={<UndoOutlined />} /></Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.paymentMethods}
                    loading={this.state.loadingPaymentMethods}
                    pagination={this.state.pagination}
                    onChange={(pagination, filters, sorter, extra) => this.changePage(pagination, filters, sorter, extra)}
                />

                <Modal centered open={this.state.showAddPaypalModal} title='Please wait...' footer={false} closable={false}>
                    <div className='text-center'>
                        <Loading />
                    </div>
                </Modal>

                <AddCardModal
                    showModal={this.state.showAddCardModal}
                    reload={() => this.loadPaymentMethods()}
                    close={() => this.setState({ showAddCardModal: false })} />
            </AccessGate>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadPaymentMethods, createNewPaypalAccount, disablePaymentMethod })(PaymentMethodsTable);