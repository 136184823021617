import {httpDelete, httpGet, httpGetDownload, httpPost} from "../libs/http";

export const loadOrders = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/orders/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadInvoices = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/invoices/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCancellationRequests = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/cancellations/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const downloadInvoice = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGetDownload('/billing/invoices/' + id + '/pdf/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const downloadCreditNote = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGetDownload('/billing/credit/' + id + '/pdf/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadInvoice = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/invoices/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const loadOrder = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/orders/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const loadCredit = (page, per_page, filter = [], onSuccess = null, onFailure = null) => dispatch => {
    let statuses = [];

    for(let i = 0; i < filter.length; i++) {
        statuses.push('status=' + filter[i]);
    }

    let status_param = '';

    if(statuses.length > 0) {
        status_param = '&' + statuses.join('&')
    }

    httpGet('/billing/credit/?page=' + page + '&per_page=' + per_page + status_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const submitCancellationRequest = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/billing/cancellations/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const cancelCancellationRequest = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/billing/cancellations/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCancellationRequest = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/cancellations/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCreditNote = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/credit/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadPaymentMethods = (page, per_page, filter = [], onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/payment_methods/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createNewCard = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/billing/payment_methods/card/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createNewPaypalAccount = (onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/billing/payment_methods/paypal/', null, (res) => onSuccess(res), (err) => onFailure(err));
};

export const submitPayment = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/billing/payment/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const disablePaymentMethod = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/billing/payment_methods/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadTransactions = (page, per_page, filter = [], onSuccess = null, onFailure = null) => dispatch => {
    let statuses = [];

    for(let i = 0; i < filter.length; i++) {
        statuses.push('status=' + filter[i]);
    }

    let status_param = '';

    if(statuses.length > 0) {
        status_param = '&' + statuses.join('&')
    }

    httpGet('/billing/transactions/?page=' + page + '&per_page=' + per_page + status_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createIPAddressModification = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/billing/ip_addresses/modify/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateOrderTags = (order_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/billing/orders/' + order_id +'/tags/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadOrderInvoices = (id, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/orders/' + id + '/invoices/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const submitOrderCancellationRequest = (order_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/billing/orders/' + order_id +'/cancel/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadOverdueNotice = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/overdue-notice/', (res) => onSuccess(res), (err) => onFailure(err));
};