import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {downloadInvoice, loadInvoice} from "../../actions/billing";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Alert, Button, Card, Col, Row, Space, Table} from "antd";
import {Loading} from "../../libs/loading";
import {DateFormat} from "../shared/DateFormat";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {CreditCardOutlined, FilePdfOutlined} from "@ant-design/icons";
import InvoicePaymentModal from "./InvoicePaymentModal";
import {MoneyField} from "../shared/MoneyField";
import qs from "qs";
import {hasPermission} from "../permissions/PermissionUtils";
import AccessItem from "../permissions/AccessItem";

class InvoiceView extends React.Component {
    state = {
        invoice: null,
        loadingInvoice: true,
        showPaymentModal: false,
        showPaymentSuccess: false,
        showPaymentFailure: false
    };

    componentDidMount() {
        this.props.updatePage('Invoice #' + this.props.match.params.id);
        this.loadInvoice();

        let parsed = qs.parse(this.props.location.search.substring(1));

        if(typeof parsed.payment !== 'undefined') {
            this.setState({
                showPaymentSuccess: parsed.payment === 'success',
                showPaymentFailure: parsed.payment === 'failed'
            });
        }
    }

    loadInvoice() {
        this.props.loadInvoice(this.props.match.params.id, (res) => {
            this.setState({ loadingInvoice: false, invoice: res.data });
        }, (err) => {
            displayErrors(err.response.data);
        });
    }

    render() {
        if(this.state.loadingInvoice) {
            return <div className='text-center'><Loading /></div>;
        }

        const { invoice } = this.state;

        const itemTableColumns = [
            { title: 'Description', dataIndex: 'description', render: (item, record) => {
                let period = '';
                if(record.period_start !== null) {
                    return <span>{item.split('\n').map((item, i) => <span key={i}>{item}<br /></span>)}<p>(<DateFormat value={record.period_start} /> - <DateFormat value={record.period_end} />)</p></span>;
                }

                return <Fragment>{item} <p>{period}</p></Fragment>;
            }},
            { title: 'Qty', dataIndex: 'quantity', align: 'center' },
            { title: 'Rate', dataIndex: 'rate', align: 'center', render: (item) => <MoneyField amount={item} currency={invoice.currency} /> },
            { title: 'Sub Total', dataIndex: 'sub_total', align: 'center', render: (item) => <MoneyField amount={item} currency={invoice.currency} /> },
        ];

        const refundTableColumns = [
            { title: 'Amount', render: (item, record) => <MoneyField amount={parseFloat(record.transaction.amount)} currency={record.transaction.currency} /> },
            { title: 'Date', render: (item, record) => <DateTimeFormat value={record.date_created} /> },
            { title: 'Method', render: (item, record) => normalizeEnum(record.transaction.payment_method_type) },
            { title: 'Status', render: (item, record) => record.status },
            { title: 'Transaction ID', render: (item, record) => record.transaction.transaction_id }
        ];

        const transactionsTableColumns = [
            { title: 'Amount', render: (item, record) => <MoneyField amount={parseFloat(record.amount)} currency={record.currency} /> },
            { title: 'Date', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Method', render: (item, record) => normalizeEnum(record.payment_method_type) },
            { title: 'Transaction ID', render: (item, record) => record.transaction_id }
        ];

        // TODO: Fix invoice view

        return(
            <Card size='small'>
                <Row>
                    <Col offset={3} span={18}>
                        <Row gutter={[16, 16]}>
                            <Col flex='auto' />
                            <Col>
                                <Space>
                                    {invoice.status !== 'PAID' && invoice.status !== 'CANCELLED' && hasPermission(this.props.company.permissions, ['can_pay_invoices']) ? <Button type='primary' icon={<CreditCardOutlined />} onClick={() => this.setState({ showPaymentModal: true })}>Pay Invoice</Button> : ''}
                                    <AccessItem scopes={['can_download_invoices']}>
                                        <Button type='default' icon={<FilePdfOutlined />} onClick={() => this.props.downloadInvoice(this.props.match.params.id)}>Download PDF</Button>
                                    </AccessItem>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col hidden={!this.state.showPaymentSuccess} span={24} style={{marginTop: '10px'}}>
                                <Alert type='success' showIcon message='Payment successfully submitted! Please allow up to 5 minutes for your payment to appear.' />
                            </Col>
                            <Col hidden={!this.state.showPaymentFailure} span={24} style={{marginTop: '10px'}}>
                                <Alert type='error' showIcon message='Your payment failed! Please try again.' />
                            </Col>
                        </Row>
                        <br />
                        <Row className='invoice-content'>
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    <Col sm={12} xs={24}>
                                        <img src='/img/logo-top-dark.png' style={{maxHeight: '50px'}} alt='Priority Prospect' className='invoice-logo' />
                                    </Col>
                                </Row>
                                <br />
                                <Row gutter={[16, 16]}>
                                    <Col span={18} xs={24} sm={18} style={{verticalAlign:'middle'}}>
                                        <b>Priority Prospect OÜ</b><br />
                                        Vandu tee 6-2, Hulja<br />
                                        Lääne Virumaa, Estonia<br />
                                        45203<br />
                                        Registration No. 14059959<br />
                                        VAT No. EE101884183
                                    </Col>
                                    <Col xs={24} sm={6} className='text-right' style={{verticalAlign:'middle'}}>
                                        <h2>Invoice<br /><small>#{invoice.id}</small></h2>
                                    </Col>
                                </Row>
                                <br />
                                <Row gutter={[16, 16]} style={{marginBottom: '15px'}}>
                                    <Col xs={24} sm={18}>
                                        Invoice to:<br />
                                        <b>{invoice.company_name}</b><br />
                                        ATTN: {invoice.first_name} {invoice.last_name}<br />
                                        {invoice.address1}
                                        {invoice.address2 !== null && invoice.address2 !== '' ? <span>{invoice.address2}<br /></span> : ''}
                                        <br />{invoice.region}, {invoice.country}<br />
                                        {invoice.postal_code}
                                        {invoice.vat !== null ? <span><br />{invoice.vat.vat_id}</span> : ''}
                                    </Col>
                                    <Col xs={24} sm={6} className='text-right' style={{verticalAlign: 'middle'}}>
                                        Invoice date: <DateFormat value={invoice.date_created} /><br />
                                        Terms: NET{invoice.payment_terms}<br />
                                        Due date: <DateFormat value={invoice.date_due} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={24} gutter={[16, 16]}>
                                        <Table size='middle'
                                               columns={itemTableColumns}
                                               dataSource={invoice.items}
                                               pagination={false}
                                               rowKey={(item) => item.description}
                                               summary={() => {
                                            let columns = [];

                                            columns.push(<Table.Summary.Row key='sub_total'>
                                                <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                                <Table.Summary.Cell className='text-center no-border-cell'>Sub Total</Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center no-border-cell'><MoneyField amount={invoice.sub_total} currency={invoice.currency} /></Table.Summary.Cell>
                                            </Table.Summary.Row>);

                                            if(invoice.coupon_code !== null) {
                                                columns.push(<Table.Summary.Row key='coupon'>
                                                    <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                                    <Table.Summary.Cell className='no-border-cell text-center'>{invoice.coupon_code.name}</Table.Summary.Cell>
                                                    <Table.Summary.Cell className='text-center no-border-cell'>-<MoneyField amount={invoice.discount_amount} currency={invoice.currency} /></Table.Summary.Cell>
                                                </Table.Summary.Row>);
                                            }

                                            columns.push(<Table.Summary.Row key='tax'>
                                                <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                                <Table.Summary.Cell className='text-center no-border-cell'>Tax ({invoice.tax_rate}%)</Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center no-border-cell'><MoneyField amount={invoice.initial_tax} currency={invoice.currency} /></Table.Summary.Cell>
                                            </Table.Summary.Row>);

                                            columns.push(<Table.Summary.Row key='total'>
                                                <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                                <Table.Summary.Cell className='text-center no-border-cell'><b>Total</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center no-border-cell'><b><MoneyField amount={invoice.total_amount} currency={invoice.currency} /></b></Table.Summary.Cell>
                                            </Table.Summary.Row>);

                                            if(invoice.payment_made > 0) {
                                                columns.push(<Table.Summary.Row key='payment_made'>
                                                    <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                                    <Table.Summary.Cell className='text-center no-border-cell'>Payment Made</Table.Summary.Cell>
                                                    <Table.Summary.Cell className='text-center no-border-cell'>-<MoneyField amount={invoice.payment_made} currency={invoice.currency} /></Table.Summary.Cell>
                                                </Table.Summary.Row>);
                                            }

                                            columns.push(<Table.Summary.Row key='balance_due'>
                                                <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                                <Table.Summary.Cell className='text-center gray-cell no-border-cell'><b>Balance Due</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className='text-center gray-cell no-border-cell'><b><MoneyField amount={invoice.balance_due} currency={invoice.currency} /></b></Table.Summary.Cell>
                                            </Table.Summary.Row>);

                                            return(columns.map((item) => {
                                                return item;
                                            }))
                                        }}/>
                                    </Col>
                                </Row>
                                <br />

                                {invoice.transactions.length > 0 ? <Row span={24} gutter={[16, 16]}>
                                    <Col span={24}>
                                        <p><b>Transactions:</b></p>
                                        <Table size='small' rowKey={(item) => item.guid}  pagination={false} columns={transactionsTableColumns} dataSource={invoice.transactions} />
                                    </Col>
                                </Row> : ''}
                                {invoice.refunds.length > 0 ? <Row span={24} gutter={[16, 16]}>
                                    <Col span={24}>
                                        <p><b>Refunds:</b></p>
                                        <Table size='small' rowKey={(item) => item.guid} pagination={false} columns={refundTableColumns} dataSource={invoice.refunds} />
                                    </Col>
                                </Row> : ''}
                                {invoice.custom_notes !== null ? <Row span={24} gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Card title='Notes' size='small' bordered={false}>
                                            {invoice.custom_notes.split('\n').map((row, i) => <p key={i}>{row}</p>)}
                                        </Card>
                                    </Col>
                                </Row> : ''}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <InvoicePaymentModal
                    invoice={this.state.invoice.id}
                    showModal={this.state.showPaymentModal}
                    close={() => this.setState({ showPaymentModal: false })}
                    amount={this.state.invoice.balance_due}
                    currency={this.state.invoice.currency}
                    reloadInvoice={() => this.loadInvoice()}
                />
            </Card>);
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadInvoice, downloadInvoice })(InvoiceView);